<template>
    <div class="page">
        <div class="card ws">
            <h2>Adventurer</h2>
            <p>Adventurer (冒険者, Bōken-sha) is a term that is associated with individuals who undertake official quests offered by the Adventurer's Guild.</p>
            
            <h2>Overview</h2>
            <p>Adventurers are individuals registered under the Adventurer's Guild, regardless of race or ethnicity, who undertake quests offered from the Adventurer's Guild and complete them in exchange for a monetary reward.</p>
            <p>The nature and fatality of these requests can vary, including, but not limited to monster subjugation, escort, and investigation. Urgent mail delivery is a common job for the trade. To ensure that the adventurers are fairly compensated, there is a bare minimum sum that has to be offered by the poster of the said quest, as stipulated in the Association's rules and regulations.</p>
            <p>It is not compulsory for an adventurer to form a party, as some are well known to be able to fend for themselves and manage alone. However, the likelihood of survival and success in carrying out quests would increase should they form a party. Still, many novice adventurers die on their first mission mostly due to underestimating their targets, especially goblins.</p>
            <p>According to Female Knight, most male adventurers tend to get married to either princesses or village girls, as there have been times where parties broke up because love between members got in the way.</p>
            
            <h2>Adventurer Tag</h2>
            <p>Upon filling out the adventurer sheet, newly registered adventurers are bestowed with a dog tag-like plate, stamped with the Adventurer's Guild logo. The material of the tag serves as both an identification with the individual's personal details inscribed behind and a testament of their official rank.</p>
            <p>As adventurers are the face the Adventurer's Guild, the rank assigned to them during a promotion interview is made based on a variety of factors, such as their trustworthiness and ability in completing quests.</p>
            <p>The Adventurer's Guild tag is also used to corroborate the said-person's identity if anything happens to them. Should the body of a deceased adventurer be rendered unrecognizable, the tag will serve as identification. </p>
            
            <h2>Ranks</h2>
            <p>Adventurers are identified by the rank officially assigned to them by the Association of the Adventurer's Guild. An adventurer's rank is recognized by the material used in one's licensed tag. The rank of Adventurers ascends from Rank 10 (Porcelain) to Rank 1 (Platinum). </p>
            <p>The material for the tag is made depending on one's rank as an adventurer. All newly registered adventurers of the Adventurer's Guild start off with a porcelain tag, indicating the lowest rank. As an adventurer's rank changes, so does the material of their tag. </p>
            <p>Gold-ranked adventurers mainly work for the national government. Becoming Platinum-ranked is rare, and only a few people in history have attained this rank. There have only been ten adventurers known to have reached this rank, the most recent of which is Hero.</p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <a href="https://goblin-slayer.fandom.com/wiki/Adventurer" target="_blank">Source</a>
        </div>
        <div v-if="false" >
        <div class="card character" v-for="c in characters" :key="c.name">
            <img :src="c.image">
            <h2>{{c.name}}</h2>
            <h3 v-for="n in c.alt_name" :key="n">{{n}}</h3>
            <table v-for="t in c.tables.filter(t=>t.type===1)" :key="t.type + Math.random()*100">
                <tr v-for="(row) in t.rows" :key="row[0]">
                    <th>{{row[0]}}</th>
                    <td v-for="r in row.filter((z,i)=>i>0)" :key="r">{{r}}</td>
                </tr>
            </table>
            <a v-for="u in c.source" :key="u" :href="u">Source</a>
        </div>
        </div>
    </div>
</template>
<script>
import characters from '@/data/character-manifest.json'

export default {
    name: 'characters',
    components: {},
    data: function () {
        return {
            characters: characters
        }
    }
}
</script>
<style>
.character table {
    text-align: left;
    overflow: auto;
    max-width: 100%;
}
</style>